<template>
  <div>
    <CRow>
      <CCol xs="12" lg="12">
        <CCard>
          <CCardHeader>
            <div class="row">
              <CCol sm="6" class="align-self-center">
                <strong>{{ $lang.user.crud.view }}</strong>
              </CCol>
            </div>
          </CCardHeader>
          <CCardBody>

            <div class="detail-div">
              <CRow>
                <CCol md="3">
                  <CRow>
                    <CCol md="12" class="text-left">
                      <img alt="Profile Pic" :src="imageURL" height="auto" width="100%"/>
                    </CCol>
                  </CRow>
                </CCol>
                <CCol md="9" class="align-self-center">
                  <CRow>
                    <CCol sm="6" md="4" lg="4">
                      <p class="custome-labal">{{ $lang.user.form.name }}</p>
                      <p class="custome-detail">{{ user.name }}</p>
                    </CCol>
                    <CCol sm="6" md="4" lg="4">
                      <p class="custome-labal">{{ $lang.user.form.email }}</p>
                      <p class="custome-detail">{{ user.email }}</p>
                    </CCol>
                    <CCol sm="6" md="4" lg="4">
                      <p class="custome-labal">{{ $lang.user.form.mobileNumber }}</p>
                      <p class="custome-detail">{{ user.mobileNumber }}</p>
                    </CCol>
                    <CCol sm="6" md="4" lg="4">
                      <p class="custome-labal">{{ $lang.user.form.address1 }}</p>
                      <p class="custome-detail">{{ user.address1 }}</p>
                    </CCol>
                    <CCol sm="6" md="4" lg="4">
                      <p class="custome-labal">{{ $lang.user.form.country }}</p>
                      <p class="custome-detail">{{ user.country.label }}</p>
                    </CCol>
                    <CCol sm="6" md="4" lg="4">
                      <p class="custome-labal">{{ $lang.user.form.state }}</p>
                      <p class="custome-detail">{{ user.state.label }}</p>
                    </CCol>
                    <CCol sm="6" md="4" lg="4">
                      <p class="custome-labal">{{ $lang.user.form.city }}</p>
                      <p class="custome-detail">{{ user.city.label }}</p>
                    </CCol>
                    <CCol sm="6" md="4" lg="4">
                      <p class="custome-labal">{{ $lang.user.form.role }}</p>
                      <p class="custome-detail">{{ user.role.label }}</p>
                    </CCol>
                  </CRow>
                </CCol>
              </CRow>
            </div>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>
<script>
import Vue from "vue";
import VueForm from "vue-form";
import store from "/src/store/store.js";
import {
  staff,
  role,
  country,
  state,
  city,
} from "/src/store/url.js";
import {Mixin} from "/src/mixins/index.js";
import options from "/src/validations/validations.js";
import {ServerTable} from "vue-tables-2";
import VueSweetalert2 from "vue-sweetalert2";
import {serverBus} from "../../main";
import VueTelInput from "vue-tel-input";
import "vue-tel-input/dist/vue-tel-input.css";
import {Maxlength} from "../../store/maxlength";

Vue.use(VueTelInput);
Vue.use(VueSweetalert2);
Vue.use(ServerTable, {}, false);
Vue.use(VueForm, options);
export default {
  name: "StaffEdit",
  mixins: [Mixin],
  data() {
    return {
      bindProps: {
        mode: "international",
        placeholder: "Contact No.",
        defaultCountry: "IN",
        dropdownOptions: {
          showFlags: true,
        },
        inputOptions: {
          maxlength: Maxlength.user.mobile,
        },
        validCharactersOnly: true,
      },
      responseMessageColor: "",
      submitted: false, //Enabled Submit Button
      roleOptions: [],
      countryOptions: [],
      StateOptions: [],
      cityOptions: [],
      imageURL: "",
      imageRemove: "",
      FILE: null,
      existImage: false,
      imageOutput: "",
      saving: false,
      saved: false,
      adminId: "",
      err_msg: "",
      largeModal: false,
      submitType: "",
      alertMessage: "test",
      showAlert: false,
      dismissCountDown: 0,
      self: this,
      msg: "",
      items: [],
      activePage: 1,
      module: staff,
      moduleRole: role,
      moduleCountry: country,
      moduleState: state,
      moduleCity: city,
      avatar: {
        imageURl: "",
      },
      user: {
        name: "",
        //  username: '',
        email: "",
        imageURL: "",
        role: "",
        address1: "",
        country: "",
        state: "",
        city: "",
        mobileNumber: "",
        mobileNumberCode: "",
      },
    };
  },
  mounted() {
    let self = this;
    self.dismissCountDown = 0;
    localStorage.setItem("showAlert", "");
    self.dismissCountDown = 0;
    const id = this.$route.params.id;
    self.dismissCountDown = 0;
    self.editId = id;
    axios
        .get(this.viewUrlApi(this.module, self.editId))
        .then((response) => {
          const responseData = response.data.data;
          self.user.username = responseData.username;
          self.user.name = responseData.first_name;
          self.user.email = responseData.email;
          self.imageURL = responseData.original;
          self.user.mobileNumber = responseData.details.mobile;
          self.user.address1 = responseData.details.address1;
          self.user.mobileNumber = responseData.details.mobile;

          self.user.country = {
            value: responseData.details.countryId,
            label: responseData.details.countryName,
          };
          self.user.state = {
            value: responseData.details.stateId,
            label: responseData.details.stateName,
          };
          self.user.city = {
            value: responseData.details.cityId,
            label: responseData.details.cityName,
          };
          if (responseData.imageUrl !== "") {
            serverBus.$emit("send_cropper_image_url", responseData.original);
            self.existImage = true;
          }
          self.user.role = {
            value: responseData.roleId,
            label: responseData.role,
          };
          store.commit("showLoader", false); // Loader Off
        })
        .catch(function (error) {
          store.commit("showLoader", false); // Loader Off
        });
  },
  watch: {
    $route: {
      immediate: true,
      handler(route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page);
        }
      },
    },
    avatar: {
      handler: function () {
        this.saved = false;
      },
      deep: true,
    },
  },
  methods: {},
};
</script>
<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
